/* App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  background-image: linear-gradient(to bottom right, #6c5b7b, #c06c84);
  /* You can adjust the gradient colors to your preference */
  background-attachment: fixed;
  /* This will ensure the background stays fixed while scrolling */
  background-size: cover;
  /* This will make sure the background covers the entire viewport */
}

.App {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f5f5f5;
}

@media screen and (max-width: 600px) {
  .App {
    margin: 20px;
    padding: 10px;
  }
}
